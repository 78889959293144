/* eslint-disable sonarjs/prefer-while */
import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import PropTypes from 'prop-types';
import layoutContext from '../components/layoutContext';
import Rehype from 'rehype-react';

import { DEFAULT_TITLE, DEFAULT_DESCRIPTION } from '../constants/Languages';
import { GLOBAL_CREDIT } from '../constants/SEOTokens';

import Slider from '../components/Slider/Slider';
import InputSubmit from '../components/Common/Input/InputSubmit';
import PartnersTab from '../components/Common/PartnersTab/PartnersTab';
import Calculator from '../components/Calculator';

const renderAst = new Rehype({
  createElement: React.createElement,
  components: {
    'slider': Slider,
    'input-submit': InputSubmit,
    'partners-tab': PartnersTab,
    'calculator': Calculator,
  },
}).Compiler;

const Default = ({
  data: {
    markdownRemark: {
      frontmatter: { 
        title,
        lang,
        path,
        desc,
        headLine,
        canonicalUrl,
        disableTitlePostfix,
        disableDescriptionPostfix
      },
      htmlAst
    }
  }
}) => {

  const postfix = disableDescriptionPostfix ? '' : ` | ${DEFAULT_DESCRIPTION[lang]}`;

  const metaTitle = title ? title : `${headLine} | ${DEFAULT_TITLE[lang]}`;
  const metaDescription = desc ? desc :
    `${GLOBAL_CREDIT} - ${headLine}${postfix}`;
  useContext(layoutContext).setCult(lang);

  function* processData(Ast) {
    if (!Ast) {
      return;
    }

    for (let i = 0; i < Ast.length; i++) {
      const node = Ast[i];
      yield node.tagName;

      if (node.children) {
        yield* processData(node.children);
      }
    }
  }

  const it = processData(htmlAst.children);
  let res = it.next();

  for (; ;) {
    if (res.value === 'h1' || res.done) {
      break;
    }
    res = it.next();
  }
  return (
    <>
      <Seo
        title={metaTitle}
        path={path}
        description={metaDescription}
        canonicalUrl={canonicalUrl}
        disableTitlePostfix={disableTitlePostfix}
      />
      {!!res.value === 'h1' && <h1>{headLine}</h1>}
      {renderAst(htmlAst)}
    </>
  );
};

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        title
        path
        tags
        lang
        desc
        headLine
        canonicalUrl
        disableTitlePostfix
        disableDescriptionPostfix
      }
    }
  }
`;

Default.propTypes = {
  data: PropTypes.object
};

export default Default;
