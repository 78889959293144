import React from 'react';
import PropTypes from 'prop-types';

import './PartnersTab.scss';

const Address = ({address, index}) => (
  <tr>
    <td>{index}</td>
    <td className='address'>{address}</td>
  </tr>
);

export default Address;

Address.propTypes = {
  address: PropTypes.string,
  index: PropTypes.number,
};
