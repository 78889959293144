import React from 'react';
import PropTypes from 'prop-types';

import './PartnersTab.scss';

const Tab = ({region, activeRegion, setActiveRegion}) => (
  <div className={`region-name ${region === activeRegion ? 'active' : 'inactive'}`} onClick={() => setActiveRegion(region)}>{region}</div>
);

export default Tab;

Tab.propTypes = {
  region: PropTypes.string,
  activeRegion: PropTypes.string,
  setActiveRegion: PropTypes.func,
};
