import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';
import Address from './Address';

import { apiLink } from '../../../constants/ApiLink';
import layoutContext from '../../layoutContext';
import { PARTNERS_TOKENS } from '../../../constants/Languages';


import './PartnersTab.scss';

const regionDefaultState = 'Київська';

const PartnersTab = () => {
  const { cult } = useContext(layoutContext);
  const [regionsData, setRegions] = useState(null);
  const [activeRegion, setActiveRegion] = useState(regionDefaultState);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    return fetch(apiLink)
      .then(response => response.json())
      .then(data => setRegions(data));
  }

  const { partnersAddresses } = PARTNERS_TOKENS[cult];

  return (
    <>{regionsData && <div className='partners-tabs'>
      <div className='regions'>
        {Object.keys(regionsData).map((key) => (
          <Tab
            key={key} region={key}
            activeRegion={activeRegion} setActiveRegion={setActiveRegion}
          />
        ))
        }
      </div>
      {Object.keys(regionsData).map((key) => (
        <div key={key} className={`addresses ${key === activeRegion ? 'address-active' : 'address-none'}`}>
          <table>
            <thead>
              <tr>
                <th>№</th>
                <th>{partnersAddresses}</th>
              </tr>
            </thead>
            <tbody>
              {regionsData[key].map((address, index) => (
                <Address
                  key={address} address={address}
                  index={index + 1}
                />
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>}</>
  );
};

export default PartnersTab;


PartnersTab.propTypes = {
  regions: PropTypes.object,
  addresses: PropTypes.object
};
