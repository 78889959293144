import React, { useState } from 'react';

import './Input.scss';

const InputSubmit = () => {
  const [attachment, setAttachment] = useState('');


  function handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData();

    const data = { attachment };

    for (let name in data) {
      formData.append(name, data[name]);
    }

    fetch('/sendMail', {
      method: 'POST',
      body: formData
    })
      .then(res => res.text())
      // eslint-disable-next-line no-console
      .catch(e => console.error(e));

    setAttachment('');
  }

  return (
    <div className='border-line'>
      <form
        name='cv'
        method='post'
        className='input-form'
        encType='multipart/form-data'
        id='vacancyForm'
        onSubmit={handleSubmit}
      >
        <input
          type='file' name='cv'
          className='input-file'
          onChange={e => setAttachment(e.target.files[0])}
          id='file'
          required
        />
        <input
          type='submit' value='Отправить'
          className='submit-button'
        />
      </form>
    </div>
  );
};

export default InputSubmit;

